/**
 * Generate a string of concatenated class names based on predefined classes
 * and a dynamic object of class conditions.
 *
 * @param {string} predefinedClasses - The pre-defined class names.
 * @param {Object} classObj - The object containing dynamic class conditions.
 * @returns {string} - The concatenated string of class names.
 *
 * @example
 * // Example usage of classNames function
 * const classes = classNames("static-class", {
 *   "conditional-class-1": true,
 *   "conditional-class-2": false,
 *   "conditional-class-3": someCondition,
 * });
 * // Result: "static-class conditional-class-1 conditional-class-3"
 */
export function classNames(predefinedClasses, classObj) {
    const dynamicClassString = Object.keys(classObj)
        .filter(className => classObj[className])
        .join(' ');

    return `${predefinedClasses} ${dynamicClassString}`.trim();
}
