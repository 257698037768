import { Typography } from '@mui/material'


const Subscription = () => {
 
  return (
    <>
    <div className='conatainer d-flex jcc'>
        <Typography variant='h1'>Coming soon ...</Typography>
    </div>
    
    </>
  )
}

export default Subscription