import { TOGGLE_THEME } from '../Action';

const state = { theme: 'dark' };

const UiReducers = (UiState = { ...state }, action) => {
    switch (action.type) {
        case TOGGLE_THEME:
            return { ...UiState, theme: UiState.theme === 'light' ? 'dark' : 'light' };

        default:
            return UiState;
    }
};


export default UiReducers;
