import { styled } from '@mui/material/styles';


export const TableData = styled('div')(({ theme }) => ({
    '.tableHead':{
        fontSize: '13px',
        fontWeight: '600',
        lineHeight: '20px',
        // color: '#000000'
    }
}));