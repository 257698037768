import { useTheme } from '@mui/material/styles';
import SVGContainer from './SVGContainer';
import { classNames } from '../../Shared/classNames';

/**
 * AngleIcon Component - SVG icon representing an arrow angle.
 *
 * @component
 * @param {Object} props - The properties of the component.
 * @param {number} [props.width=20] - The width of the icon.
 * @param {number} [props.height=20] - The height of the icon.
 * @param {string} [props.color] - The color of the icon. If not provided, it uses the primary color from the theme.
 * @param { 'up'  | 'down' | 'left' | 'right'} [props.face] - The direction the arrow is facing.
 * @param {string} [props.className] - Additional class names to be applied to the SVGContainer.
 * @param {Object} restProps - Additional props to be spread on the SVGContainer.
 * @returns {JSX.Element} - The rendered AngleIcon component.
 *
 * @example
 *
 * <AngleIcon
 *   width={30}
 *   height={30}
 *   color="blue"
 *   face="left"
 *   className="custom-icon"
 *   onClick={handleClick}
 * />
 */

function ArrowIcon({ width = 30, height = 30, color: iconColor, face = 'up', className = '', ...restProps }) {
   const theme = useTheme();
   const color = iconColor || theme.colors.color.primary;
   const faceIncludeInClassName = className.includes('face-');

   return (
      <SVGContainer {...restProps} className={classNames(className, {
         'face-up': !faceIncludeInClassName && face === 'up',
         'face-down': !faceIncludeInClassName && face === 'down',
         'face-left': !faceIncludeInClassName && face === 'left',
         'face-right': !faceIncludeInClassName && face === 'right'
      })}>
         {/* SVG path elements for the arrow icon */}
         <svg
            xmlns="http://www.w3.org/2000/svg"
            width={width} height={height}
            color={color}
            viewBox="0 0 24 24"
         >
            <path
               stroke="currentColor"
               strokeLinecap="round"
               strokeLinejoin="round"
               strokeWidth="2"
               d="M12 6v12m0 0l-5-5m5 5l5-5"
            ></path>
         </svg>
      </SVGContainer>
   );
}

export default ArrowIcon;
