import React from "react";
import { StyledRecentSales } from "./style";
import { Card, CardContent, CardHeader } from "@mui/material";
import DataTable from "../DataTable/DataTable";

const columns = [
    { id: 'datetime', label: 'Date & Time' },
    { id: 'product', label: 'Product' },
    { id: 'store', label: 'Store' },
    { id: 'amount', label: 'Amount' },
];

const data = [
    { id: 1, datetime: "02-01 00:00", product: "ABCD", store: "ABCD", amount: "123" },
    { id: 2, datetime: "02-01 00:00", product: "ABCD", store: "ABCD", amount: "123" },
    { id: 3, datetime: "02-01 00:00", product: "ABCD", store: "ABCD", amount: "123" },
    { id: 4, datetime: "02-01 00:00", product: "ABCD", store: "ABCD", amount: "123" },
    { id: 5, datetime: "02-01 00:00", product: "ABCD", store: "ABCD", amount: "123" },
    { id: 6, datetime: "02-01 00:00", product: "ABCD", store: "ABCD", amount: "123" },
    { id: 7, datetime: "02-01 00:00", product: "ABCD", store: "ABCD", amount: "123" },
    { id: 8, datetime: "02-01 00:00", product: "ABCD", store: "ABCD", amount: "123" },
];

export default function RecentSales({ header }){
    return(
        <>
            <StyledRecentSales>
                <Card className="card">
                    <CardHeader 
                        title={header}
                        // action={
                        //     <Button>Filter</Button>
                        // }
                    />
                    <CardContent>
                        <DataTable columns={columns} data={data} scrollable={true}/>
                    </CardContent>
                </Card>
            </StyledRecentSales>
        </>
    )
}