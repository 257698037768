import { SET_DATE_COLOUMN } from '../Action';

const state = { dateColumn: '' };

const PredictionReducers = (predeictionState = { ...state }, action) => {
    switch (action.type) {
        case SET_DATE_COLOUMN:
            return { ...predeictionState, dateColumn: action.payload };

        default:
            return predeictionState;
    }
};

export default PredictionReducers;
