import { useTheme } from '@mui/material/styles';
import SVGContainer from './SVGContainer';

function CustomersIcon({ width = 21, height = 21, color: iconColor, ...restProps }) {
    const theme = useTheme();
    const color = iconColor || theme.colors.color.primary;
    return (
        <SVGContainer {...restProps}>

            <svg
                width={width} height={height}
                color={color}
                viewBox="0 0 32 32"
            >
                <g fill='currentColor'>
                    <path d="M16 21.916c-4.797.02-8.806 3.369-9.837 7.856l-.013.068a.75.75 0 001.464.325l.001-.005c.875-3.885 4.297-6.744 8.386-6.744s7.511 2.859 8.375 6.687l.011.057a.75.75 0 00.732.59h.001a.745.745 0 00.165-.019l-.005.001a.751.751 0 00.572-.898l.001.005c-1.045-4.554-5.055-7.903-9.849-7.924h-.002zM9.164 10.602a4.676 4.676 0 10-4.676-4.676 4.682 4.682 0 004.675 4.676zm0-7.852a3.176 3.176 0 11-3.176 3.176A3.18 3.18 0 019.163 2.75zm13.762 7.852a4.676 4.676 0 10-4.676-4.676 4.682 4.682 0 004.675 4.676zm0-7.852a3.176 3.176 0 11-3.176 3.176 3.18 3.18 0 013.176-3.176zm7.896 17.09c-.878-3.894-4.308-6.759-8.406-6.759-.423 0-.839.031-1.246.089l.046-.006a.717.717 0 00-.133.047l.004-.002c-.751-2.129-2.745-3.627-5.089-3.627a5.387 5.387 0 00-5.068 3.561l-.012.038c-.017-.004-.03-.014-.047-.017a8.35 8.35 0 00-1.195-.084h-.007a8.653 8.653 0 00-8.392 6.701l-.011.058a.75.75 0 001.464.325l.001-.005c.737-3.207 3.56-5.565 6.937-5.579h.002c.335 0 .664.024.985.07l-.037-.004c-.008.119-.036.232-.036.354a5.417 5.417 0 0010.833 0v-.001c0-.12-.028-.233-.036-.352.016-.002.031.005.047.001.294-.044.634-.068.98-.068h.011-.001a7.14 7.14 0 016.93 5.531l.009.048a.745.745 0 00.897.571l-.005.001a.751.751 0 00.572-.898l.001.005zM16 18.916h-.001a3.917 3.917 0 113.917-3.917V15A3.92 3.92 0 0116 18.916z"></path>
                </g>

            </svg>
        </SVGContainer>
    );
}

export default CustomersIcon;