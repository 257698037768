import React from "react";
import { StyledDashboardGraph } from "./style";
import { Box, Card, CardContent, CardHeader, Grid, IconButton, Typography } from "@mui/material";
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import CircleIcon from '@mui/icons-material/Circle';
import RevenueGraph from "../Graphs/RevenueGraph";

export default function DashboardGraph({ header, body, graphData }) {
    return (
        <>
            <StyledDashboardGraph>
                <Card className="card">
                    <CardHeader
                        title={header}
                        action={
                            <IconButton>
                                <MoreHorizIcon />
                            </IconButton>
                        }
                    />
                    <CardContent>
                        <Box className="charts">
                            <RevenueGraph graphData={graphData} />
                        </Box>
                    </CardContent>
                </Card>
            </StyledDashboardGraph>
        </>
    )
}