import { useTheme } from '@mui/material/styles';
import SVGContainer from './SVGContainer';

function StoreIcon({ width = 20, height = 20, color: iconColor, ...restProps }) {
   const theme = useTheme();
   const color = iconColor || theme.colors.color.primary;
   return (
      <SVGContainer {...restProps}>
         <svg
            width={width} height={height}
            fill="none"
            stroke="currentColor"
            strokeWidth="3"
            viewBox="0 0 64 64"
         >
            <path d="M52 27.18v25.58a2.92 2.92 0 01-3 2.84H15a2.92 2.92 0 01-3-2.84V27.17"></path>
            <path d="M26.26 55.52L26.26 38.45 37.84 38.45 37.84 55.52"></path>
            <path d="M8.44 19.18s-1.1 7.76 6.45 8.94a7.17 7.17 0 006.1-2A7.43 7.43 0 0032 26a7.4 7.4 0 005 2.49 11.82 11.82 0 005.9-2.15 6.66 6.66 0 004.67 2.15 8 8 0 007.93-9.3L50.78 9.05a1 1 0 00-.94-.65H14a1 1 0 00-.94.66z"></path>
            <path d="M8.44 19.18L55.54 19.18"></path>
            <path d="M21.04 19.18L21.04 8.4"></path>
            <path d="M32.05 19.18L32.05 8.4"></path>
            <path d="M43.01 19.18L43.01 8.4"></path>
         </svg>
      </SVGContainer>
   );
}

export default StoreIcon;
