import BaseService from './BaseService';
import Http from '../Http';

export default class ShopifyConnectorService extends BaseService {
    static controllerArray = [];

    static shopifyConnectorInput(params = {}, signal) {
        const controller = new AbortController();
        this.controllerArray.push(controller);

        return Http.post(`/app/save-shopify-user`, params, {
            signal: signal || controller?.signal
        });
    }

    static shopifyConnectorSales(params = {}, signal) {
        const controller = new AbortController();
        this.controllerArray.push(controller);
        console.log("param", { params })
        return new Promise((resolve, reject) => {
            Http.get(`/app/sales`, { params }, {
                signal: signal || controller?.signal
            })
                .then((resp) => {
                    return resolve(resp.data);
                })
                .catch((err) => {
                    return reject(err);
                });
        });
    }
}
