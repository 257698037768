import { ThemeProvider } from '@mui/material';
import GlobalStyles from '@mui/material/GlobalStyles';
import { useSelector } from 'react-redux';
import RoutesComponent from './Routes';
import createPrimaryTheme from './theme';
import Styles from './GlobalStyle';
import './styleWithoutTheme.css';
function App() {
    const currentTheme = useSelector((store) => store.ui.theme);
    const theme = createPrimaryTheme(currentTheme);

    return (
        <>
            {!!theme ? <ThemeProvider theme={theme}>
                <GlobalStyles styles={Styles(theme)} />
                <RoutesComponent />
            </ThemeProvider> : <diiv />
            }
        </>
    );
}

export default App;
