import { useTheme } from '@mui/material/styles';
import SVGContainer from './SVGContainer';

function HamburgerMenuIcon({ width = 22, height = 14, color: iconColor, ...restProps }) {
   const theme = useTheme();
    const color = iconColor || theme.colors.color.primary;
   return (
      <SVGContainer {...restProps}>
         <svg
            width={width}
            height={height}
            color={color}
            viewBox="0 0 22 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
         >
            <line
               x1="0.75"
               y1="1.25"
               x2="21.25"
               y2="1.25"
               stroke="currentColor"
               strokeWidth="1.5"
               strokeLinecap="round"
            />
            <line
               x1="0.75"
               y1="7.25"
               x2="21.25"
               y2="7.25"
               stroke="currentColor"
               strokeWidth="1.5"
               strokeLinecap="round"
            />
            <line
               x1="0.75"
               y1="13.25"
               x2="21.25"
               y2="13.25"
               stroke="currentColor"
               strokeWidth="1.5"
               strokeLinecap="round"
            />
         </svg>
      </SVGContainer>
   );
}

export default HamburgerMenuIcon;
