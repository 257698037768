import React, { useEffect, useLayoutEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import { Card, CardHeader, CardContent, Button, Box, Divider, Typography, Grid, LinearProgress, linearProgressClasses, FormGroup, TextField } from '@mui/material';
import MapChart from '../../../Components/MapChart/MapChart';
import { StoreIcon } from '../../../Components/Icons';
import DashboardMetrics from '../../../Components/Dashboard/DashboardMetrics';
import { StyledDashboardGrid } from './style';
import { CustomersIcon, SalesIcon, TransationIcon } from '../../../Components/Icons';
import DashboardGraph from '../../../Components/DashboardGraph/DashboardGraph';
import StoreSales from '../../../Components/StoreSales/StoreSales';
import GradientGraph from '../../../Components/Graphs/GradientGraph';
import SalesService from '../../../Services/SalesService';
import ItemsService from '../../../Services/ItemsService';
import AccessModal from '../../../Components/Modals/AccessModal';
import { Form, Formik } from 'formik';
import { ShopifyConnectorValidator } from '../../../Shared/Validator';
import ShopifyConnectorService from '../../../Services/ShopifyConnectorService';
import CustomersService from '../../../Services/CustomersService';
import TransactionService from '../../../Services/TransactionService';
import ActivityService from '../../../Services/ActivityService';
import RecentSales from '../../../Components/RecentSales/RecentSales';

const BorderLinearProgress1 = styled(LinearProgress)(({ theme }) => ({
    height: 30,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
        backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
    },
    [`& .${linearProgressClasses.bar}`]: {
        borderRadius: 5,
        backgroundColor: theme.palette.mode === 'light' ? '#F79319' : '#F79319',
    },
}));

const BorderLinearProgress2 = styled(LinearProgress)(({ theme }) => ({
    height: 30,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
        backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
    },
    [`& .${linearProgressClasses.bar}`]: {
        borderRadius: 5,
        backgroundColor: theme.palette.mode === 'light' ? '#4C73F7' : '#4C73F7',
        position: 'absolute',
        left: 0,
        top: 0,
        bottom: 0,
        zIndex: 1,
    },
    '& .progress-text': {
        zIndex: 2,
        position: 'absolute',
        left: '50%',
        top: '50%',
        transform: 'translate(-50%, -50%)',
        color: 'white',
        fontWeight: 'bold',
    },
}));

export default function Dashboard() {
    const pointData = [{ title: "New Delhi", latitude: 28.6353, longitude: 77.225 }];
    const [totalSales, setTotalSales] = useState(null);
    const [customers, setCustomers] = useState(null);
    const [transaction, setTransaction] = useState(null);
    const [monthlyRevenue, setMonthlyRevenue] = useState(null);
    const [salesAnalysis, setSalesAnalysis] = useState(null);
    const [itemPerformnceData, setItemPerformnceData] = useState({
        least_item: { product: null, total_sold: null },
        most_item: { item_id: null, total_sold: null }
    });
    const [loading, setLoading] = useState({
        totalSalesLoading: false,
        customersLoading: false,
        transactionLoading: false,
        monthlyRevenueLoading: false,
        itemPerformnceDataLoading: false,
        salesAnalysisLoading: false
    })

    const [modalOpen, setModalOpen] = useState(false);

    const {customersLoading,itemPerformnceDataLoading,monthlyRevenueLoading,totalSalesLoading,transactionLoading,salesAnalysisLoading} = loading;

    const handleLoading = (isLoad = false,key = null) => {
        console.log(isLoad,key);
        if(!key) {
            setLoading((prev)=> ({...prev,customersLoading: isLoad,itemPerformnceDataLoading: isLoad,monthlyRevenueLoading: isLoad,totalSalesLoading: isLoad,transactionLoading: isLoad,salesAnalysisLoading:isLoad}))
        };
        setLoading((prev)=> ({...prev,[key]:isLoad}))
    }
 
    const fetchInitialData = () => {
        handleLoading(true, null);
        const apisToFetch = [SalesService.getTotalSales(), SalesService.getMonthlyRevenue(), ItemsService.getItemPerformance(), CustomersService.getCustomers(), TransactionService.getTransaction(), ActivityService.getSalesAnalysis()];
        Promise.all([...apisToFetch]).then((resp) => {
            
            if (resp[0].success) {
                handleLoading(false,'totalSalesLoading')
                console.log("Total Sales",resp[0].data);
                setTotalSales(resp[0].data);
            }

            if (resp[1].success) {
                handleLoading(false,'monthlyRevenueLoading')
                setMonthlyRevenue(resp[1].data);
            }

            if (resp[2].success) {
                handleLoading(false,'itemPerformnceDataLoading')
                console.log("Items",resp[2].data);
                setItemPerformnceData(resp[2].data);
            }

            if (resp[3].success) {
                handleLoading(false,'customersLoading')
                setCustomers(resp[3].data);
            }

            if (resp[4].success) {
                handleLoading(false,'transactionLoading')
                setTransaction(resp[4].data);
            }

            if (resp[5].success) {
                console.log("salesAnalysis",resp[5].data);
                // setTransaction(resp[5].data);
                handleLoading(false,'salesAnalysisLoading')
                setSalesAnalysis(resp[5].data);
            }

        }).catch(err => {
            setModalOpen(true)
            handleLoading(false, null);
        })
    }

    const handleCloseModal = () => {
        setModalOpen(false);
    };

    useLayoutEffect(() => {
        fetchInitialData();
    }, []);


    const { least_item, most_item } = itemPerformnceData;

    return (
        <>
            {/* <AccessModal open={modalOpen} modalTitle="Shopify Connector" onClose={handleCloseModal}>
                <Formik
                    initialValues={{
                        store_id: '',
                        module_name: '',
                        api_key: '',
                        access_token: '',
                        api_password: '',
                        vendor_name: 'shopify'

                        // "store_id": "6e91a2-3",
                        // "store_name": "orders",
                        // "api_key": "3217760add2496747a22a255a0a7b4c3",
                        // "api_password": "0ff505acd0c0d3f4dc6c9d860b910581",
                        // "access_token": "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"
                    }}
                    validationSchema={ShopifyConnectorValidator}
                    onSubmit={(values, { setSubmitting }) => {
                        // console.log(values);
                        ShopifyConnectorService.shopifyConnectorInput(values)
                            .then((res) => {
                                if (res.status === 201) {
                                    setModalOpen(false);
                                    // console.log("okaasdafewyyyyg");
                                    // navigate('/login');
                                }
                                setSubmitting(false);
                                fetchInitialData();
                                console.log("data", res);
                            })
                            .catch(() => setSubmitting(false));
                    }}
                >
                    {({ values, handleChange, touched, errors, isSubmitting }) => (
                        <Form noValidate>
                            <FormGroup>
                                <TextField
                                    name="store_id"
                                    type="text"
                                    placeholder="Store Id"
                                    onChange={handleChange}
                                    id="store_id"
                                    value={values.store_id}
                                    error={touched.store_id && !!errors?.store_id}
                                    helperText={touched.store_id && errors?.store_id ? errors?.store_id : ''}

                                />
                            </FormGroup>
                            <FormGroup>
                                <TextField
                                    id="module_name"
                                    name="module_name"
                                    type="text"
                                    placeholder="Module Name"
                                    onChange={handleChange}
                                    value={values.module_name}
                                    error={touched.module_name && !!errors?.module_name}
                                    helperText={touched.module_name && errors?.module_name ? errors?.module_name : ''}
                                />
                            </FormGroup>

                            {JSON.stringify(errors)}
                            <FormGroup>
                                <TextField
                                    id="api_key"
                                    name="api_key"
                                    type="text"
                                    placeholder="Api Key"
                                    onChange={handleChange}
                                    value={values.api_key}
                                    error={touched.api_key && !!errors?.api_key}
                                    helperText={touched.api_key && errors?.api_key ? errors?.api_key : ''}
                                />
                            </FormGroup>

                            <FormGroup>
                                <TextField
                                    id="access_token"
                                    name="access_token"
                                    type="text"
                                    placeholder="Access Token"
                                    onChange={handleChange}
                                    value={values.access_token}
                                    error={touched.access_token && !!errors?.access_token}
                                    helperText={touched.access_token && errors?.access_token ? errors?.access_token : ''}
                                />
                            </FormGroup>

                            <FormGroup>
                                <TextField
                                    id="api_password"
                                    name="api_password"
                                    type="text"
                                    placeholder="Api Password"
                                    onChange={handleChange}
                                    value={values.api_password}
                                    error={touched.api_password && !!errors?.api_password}
                                    helperText={touched.api_password && errors?.api_password ? errors?.api_password : ''}
                                />
                            </FormGroup>

                            {isSubmitting && <LinearProgress />}
                            <Box>
                                <Button type="submit" sx={{ width: '100%', padding: '10px' }}>
                                    <Typography>Save</Typography>
                                </Button>
                            </Box>
                        </Form>
                    )}

                </Formik>

            </AccessModal> */}
            <StyledDashboardGrid>
                <div className='left-part'>
                    <div className='matrix-part'>
                        <DashboardMetrics header={() => <div className='d-flex aic mt ml'>
                            <SalesIcon className="mr" />
                            Total Sales </div>}
                            body={<>{totalSalesLoading ? "Loading..." : `₹ ${totalSales ? totalSales : 'N/A'}`}</>}
                            growthPercentage={-24}
                            lastMonth="30K"
                        />

                        <DashboardMetrics header={() => <div className='d-flex aic mt ml'>
                            <CustomersIcon className="mr" />
                            Customers </div>}
                            body={<>{customersLoading ? "Loading..." : `₹ ${customers ? customers : 'N/A'}`}</>}
                            growthPercentage={0.1}
                            lastMonth="23K"
                        />

                        <DashboardMetrics header={() => <div className='d-flex aic mt ml'>
                            <TransationIcon className="mr" />
                            Avg Transaction Value </div>}
                            body={<>{transactionLoading ? "Loading..." : `₹ ${transaction ? transaction : 'N/A'}`}</>}
                            growthPercentage={11}
                            lastMonth="21.46K"
                        />
                        {/* className="coming-soon" */}
                    </div>
                    <div className='graph-part'>
                        <DashboardGraph
                            header={<>Revenue</>}
                            body={<>$106.46</>}
                            graphData={monthlyRevenue}
                        />
                    </div>
                    <div className='offline mt'>
                        <StoreSales
                            header={<>Store wise sales</>}
                        />
                    </div>
                </div>
                <div className='right-part'>
                {/* className="offline" */}
                    <Box>
                        {/* <Card>
                            <CardHeader titleTypographyProps={{ variant: 'h3' }} title={<div className='d-flex aic jcsb'>
                                <div className='flex-center'>
                                    <StoreIcon className="mr" />
                                    Active Stores
                                </div>


                                <Button>Filter</Button>
                            </div>} />
                            <CardContent>
                                <MapChart width={'100%'} height={'13rem'} pointData={pointData} />
                            </CardContent >
                        </Card> */}
                        <RecentSales
                            header={<>Recent Sales</>}
                        />
                    </Box>
                    <Box className="lower-part">
                        <Card>
                            <CardHeader titleTypographyProps={{ variant: 'h3' }} title={<div className='d-flex aic jcsb'>
                                <div className='flex-center'>
                                    Activity
                                </div>

                                {/* <Button>Filter</Button> */}
                            </div>} />
                            <CardContent>
                                {salesAnalysis && salesAnalysis.top_selling_items.length > 0 ? (<GradientGraph graphData={salesAnalysisLoading ? 'Loading...' : salesAnalysis} />) : (<Box className="d-flex jcc aic"><Typography variant="h4">N/A</Typography></Box>)}
                                {/* <GradientGraph graphData={salesAnalysisLoading ? 'Loading...' : salesAnalysis} /> */}
                                <Divider sx={{ marginTop: '20px' }} />
                                <Box className='infoPerformance'>
                                    <Typography className='lowerTxt'>Item Performance</Typography>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} md={6}>
                                            <Typography className='lowerTxt1'>Least Sold Item</Typography>
                                            <Typography sx={{fontWeight: '700',fontSize: '12px'}}>{itemPerformnceDataLoading ? "Loading..." : `${least_item ? least_item.product : 'N/A'}`}</Typography>
                                            {/* <BorderLinearProgress1 variant="determinate" value={0}> */}
                                            {/* <Typography className="progressText">{least_sold_item.total_sold || 'N/A'}</Typography> */}
                                        {/* </BorderLinearProgress1> */}
                                            <div className="progress-text">{itemPerformnceDataLoading ? "Loading..." : `${least_item ? least_item.total_sold : 'N/A'}`}</div>
                                            {/* <Typography className='lowerTxt3'>-21% Last Month</Typography> */}
                                            {/* {least_item.total_sold || 'N/A'} */}
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <Typography className='lowerTxt1'>Most Sold Item</Typography>
                                            <Typography sx={{fontWeight: '700',fontSize: '12px'}}>{itemPerformnceDataLoading ? "Loading..." : `${most_item ? most_item.item_id : 'N/A'}`}</Typography>
                                            {/* <BorderLinearProgress2 variant="determinate" value={0}> */}
                                            {/* <div className="progress-text">{most_sold_item.total_sold || 'N/A'}</div> */}
                                        {/* </BorderLinearProgress2> */}
                                        {/* {most_item.item_id || 'N/A'} */}
                                            <div className="progress-text">{itemPerformnceDataLoading ? "Loading..." : `${most_item ? most_item.total_sold : 'N/A'}`}</div>
                                            {/* <Typography className='lowerTxt3'>+45% Last Month</Typography> */}
                                            {/* {most_item.total_sold || 'N/A'} */}
                                        </Grid>
                                    </Grid>
                                </Box>
                            </CardContent >
                        </Card>
                    </Box>
                </div>
            </StyledDashboardGrid>
        </>

    )
}

