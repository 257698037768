import React, { useState } from 'react'
import { Box, Dialog, IconButton } from '@mui/material'
import { DialogTitle, DialogBody, ModalTitle, ModalSubTitle } from './style'
import CloseIcon from '../Icons/CloseIcon';

function AccessModal(props) {
    const { open, onClose, height, width, children, modalTitle, subTitle } = props;
    const [dialogHeight] = useState(height);
    const [dialogWidth] = useState(width ? width : '648px');

    const minWidthDefault = dialogWidth;


    const styles = {
        padding: '5.18rem 5.87rem',
        height: dialogHeight,
        minWidth: minWidthDefault,
        overflow: 'hidden',
        borderRadius: '0.5rem',
    };

    if (window.innerWidth <= 479) {
        styles.padding = '4.18rem 2.87rem';
        styles.minWidth = '0px';
      } else if (window.innerWidth <= 767) {
        styles.padding = '4.18rem 4.87rem';
        styles.minWidth = '0px';
      }

    return (
        <Dialog
            open={open}
            onClose={onClose}
            PaperProps={{
                style: styles
            }}
        >
            <Box>
                <Box className='modal-title'>
                    <ModalTitle className='d-flex jcc aic' sx={{fontSize: '26px',fontWeight: '500', color:"#646464"}}>{modalTitle}</ModalTitle>
                    {subTitle &&
                        <ModalSubTitle>{subTitle}</ModalSubTitle>
                    }
                </Box>
                <IconButton onClick={onClose} sx={{ position: 'absolute',right: 16,top: 16 }}>
                    <CloseIcon />
                </IconButton>
            </Box>

            <DialogBody style={{maxHeight: `calc(100vh - 100px)`, overflow: 'auto'}}>
                {children}
            </DialogBody>
        </Dialog>
    )
}

export default AccessModal