import { useTheme } from '@mui/material/styles';
import SVGContainer from './SVGContainer';

function UploadIcon({ width = 24, height = 20, color: iconColor, ...restProps }) {
    const theme = useTheme();
    const color = iconColor || theme.colors.color.primary;
    return (
        <SVGContainer {...restProps}>
            <svg width={width}
                height={height} color={color} viewBox="0 0 24 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clipPath="url(#clip0_418_9)">
                    <path d="M0 9.56055C0.0404166 6.91351 0.983472 4.61126 2.88405 2.73563C4.46878 1.17086 6.38882 0.242274 8.61623 0.0461785C12.1924 -0.268672 15.0859 1.02965 17.3038 3.84434C17.4131 3.98256 17.5288 4.06489 17.71 4.09483C20.84 4.61476 23.4157 7.22787 23.9057 10.3719C24.5638 14.5937 21.7177 18.4787 17.4969 19.1163C17.1352 19.1712 16.7699 19.1937 16.4032 19.1837C15.9391 19.1712 15.5938 18.8329 15.5888 18.3824C15.5839 17.9173 15.9027 17.571 16.3732 17.5576C17.7434 17.5191 18.9968 17.1369 20.0756 16.2747C21.4298 15.1924 22.1902 13.7843 22.3359 12.0484C22.5934 8.98424 20.4498 6.26286 17.5189 5.73794C15.3723 5.35323 13.5286 5.97794 12.0142 7.5517C11.7667 7.80917 11.4893 7.96036 11.1246 7.85557C10.7878 7.75877 10.5902 7.52775 10.5268 7.18845C10.4694 6.88208 10.6061 6.63858 10.8082 6.42402C11.8695 5.29884 13.1479 4.5469 14.6543 4.18814C14.7835 4.1572 14.9152 4.13674 15.0435 4.10231C15.1113 4.08385 15.2176 4.11878 15.2391 4.03046C15.258 3.95212 15.1652 3.90821 15.1128 3.85881C14.0685 2.87385 12.854 2.20173 11.4544 1.86792C6.95416 0.793637 2.48787 3.77449 1.75538 8.34207C1.07229 12.6013 3.95135 16.6589 8.19111 17.4109C8.41964 17.4513 8.65066 17.4812 8.88118 17.5107C9.37616 17.574 9.70049 17.9737 9.63663 18.4467C9.57425 18.9103 9.17757 19.1932 8.68359 19.1438C6.4956 18.9262 4.59602 18.0615 3.0038 16.5561C1.43055 15.0687 0.481507 13.235 0.121749 11.0974C0.0374228 10.5954 0.0224537 10.0895 0.000498971 9.56006L0 9.56055Z" fill="currentColor" />
                    <path d="M11.9074 13.2247C11.3246 13.7925 10.7592 14.3424 10.1959 14.8948C10.0507 15.0375 9.89602 15.1577 9.68795 15.1886C9.31422 15.2445 8.97941 15.0779 8.81226 14.7535C8.64759 14.4337 8.70098 14.072 8.97692 13.791C9.40204 13.3579 9.83664 12.9338 10.2688 12.5077C10.891 11.8949 11.5157 11.2852 12.1374 10.6725C12.5585 10.2573 13.0101 10.2488 13.4297 10.661C14.4861 11.6978 15.5409 12.7357 16.5922 13.7776C16.8846 14.067 16.942 14.4267 16.7703 14.757C16.5992 15.0869 16.2724 15.2475 15.8951 15.1891C15.6966 15.1587 15.5429 15.0509 15.4042 14.9132C14.8852 14.3973 14.3653 13.8823 13.8444 13.3689C13.7925 13.3175 13.7356 13.2267 13.6662 13.2501C13.5679 13.2831 13.6144 13.3948 13.6144 13.4712C13.6119 15.026 13.6129 16.5813 13.6114 18.1361C13.6114 18.2518 13.6114 18.3701 13.5899 18.4834C13.5046 18.9439 13.1623 19.2104 12.7042 19.1814C12.2926 19.1555 11.9712 18.8067 11.9523 18.3616C11.9478 18.2618 11.9508 18.162 11.9508 18.0622C11.9508 16.5488 11.9508 15.0355 11.9493 13.5221C11.9493 13.4338 11.9727 13.341 11.9074 13.2247Z" fill="currentColor" />
                </g>
                <defs>
                    <clipPath id="clip0_418_9">
                        <rect width="24" height="19.1859" fill="transparent" />
                    </clipPath>
                </defs>
            </svg>

        </SVGContainer>
    );
}

export default UploadIcon;