import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import App from './App';
import Store from './Redux/Store';
import Http from './Http';
const root = ReactDOM.createRoot(document.getElementById('root'));
const user = Store.getState().auth?.user || null;

if (!!user) {
    Http.defaults.headers.common['Authorization'] = `Bearer ${user.token}`;
}

root.render(
    <Provider store={Store}>
        <App />
    </Provider>
);
