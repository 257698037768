import { useTheme } from '@mui/material/styles';
import SVGContainer from './SVGContainer';

function SearchIcon({ width = 21, height = 21, color: iconColor, ...restProps }) {
    const theme = useTheme();
    const color = iconColor || theme.colors.color.primary;
    return (
        <SVGContainer {...restProps}>
            <svg
                width={width} height={height}
                color={color}
                fill="none"
                viewBox="0 -0.5 25 25"
            >
                <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1.5"
                    d="M5.5 11.146a6.144 6.144 0 1112.288-.002 6.144 6.144 0 01-12.288.002z"
                    clipRule="evenodd"
                ></path>
                <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1.5"
                    d="M15.989 15.49l3.511 3.511"
                ></path>
            </svg>
        </SVGContainer>
    );
}

export default SearchIcon;