import { styled } from '@mui/material/styles';

export const DialogTitle = styled('div')(({ theme }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
}))


export const DialogBody = styled('div')(({ theme }) => ({
    margin: '1.25rem 0',
}))


export const CloseModal = styled('div')(({ theme }) => ({

}))

export const ModalTitle = styled('h1')(({ theme }) => ({
    color: theme.colors.primary,
    fontSize: '1.56rem',
    fontWeight: 600,
    lineHeight: 'normal',
    textTransform: 'capitalize',
    margin: '0 0 0.25rem 0'
}))

export const ModalSubTitle = styled('h6')(({ theme }) => ({
    color: theme.colors.subTitleColor,
    fontSize: '1.12rem',
    fontWeight: 400,
    lineHeight: '1.93rem',
    textTransform: 'capitalize',
    margin:'0',
}))
