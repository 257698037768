import { Typography } from "@mui/material"



const Profile = () => {
  return (
    <>
    <div className='conatainer d-flex jcc'>
        <Typography variant='h1'>Coming soon ...</Typography>
    </div>
    </>
  )
}

export default Profile