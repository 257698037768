import { styled } from '@mui/material/styles';


export const StyledDashboardGrid = styled('div')(() => ({
    // overflowX: 'hidden',
    
    '&, .matrix-part': {
        display: 'grid',
        gridGap: '1.5rem',
        '@media(max-width: 767px)': {
            display: 'block'
         }
    },

    '&': {
        gridTemplateColumns: '3fr 1fr',
        '@media(max-width: 767px)': {
           gridTemplateColumns: '1fr'
        }
    },

    '.left-part': {
        '.matrix-part': {
            gridTemplateColumns: 'repeat(3, 1fr)',
        },
        '.graph-part':{
            gridColumn: '1 / 4',
            marginTop: '15px'
        },
    },

    '.right-part':{
        '.lower-part':{
            marginTop: '10px',
            '.infoPerformance':{
                marginTop: '10px',
                '.lowerTxt':{
                    fontWeight: "700",
                    fontSize: '14px',
                    lineHeight: '27.28px',
                },
                '.lowerTxt1':{
                    fontWeight: "500",
                    fontSize: '13px',
                    lineHeight: '24px',
                },
                '.lowerTxt2':{
                    fontWeight: "700",
                    fontSize: '20px',
                    lineHeight: '28.46px',
                },
                '.lowerTxt3':{
                    fontWeight: "500",
                    fontSize: '10px',
                    lineHeight: '32px',
                }
            },
            '@media(maxWidth: 767px)': {
                '#chartdiv':{
                    width: '35rem !important'
                }
             }

        }
    },
    '@media(max-width: 1023px)': {
        gridTemplateColumns: '1fr'
    },
    
}));



