import { useTheme } from '@emotion/react';
import React, { useEffect, useRef } from 'react';
import Chart from 'react-apexcharts';

const RevenueGraph = ({ graphData }) => {
  const theme = useTheme();
  const data = [];
  const xAxisCategories = [];
  (graphData || []).forEach(gd => {
    data.push(gd.total_revenue);
    xAxisCategories.push(`${gd.month}, ${gd.year}`)
  });

  const categoryColor = "#D4D4D4";
  const options = {
    series: [{
      name: 'Income',
      data
    }],
    chart: {
      height: 350,
      type: 'area',
      toolbar: {
        show: false,
      },
    },
    dataLabels: {
      enabled: false
    },
    colors: ["#3959ED"],
    stroke: {
      curve: 'smooth',
    },
    xaxis: {
      categories: xAxisCategories,
      labels: {
        style: {
          colors: Array(data.length).fill(categoryColor)
        }
      }
    },
    yaxis: {
      labels: {
        formatter: function (number) {
          const suffixes = ["", "K", "M", "B", "T"];
          let suffixIndex = 0;

          while (number >= 1000 && suffixIndex < suffixes.length - 1) {
            number /= 1000;
            suffixIndex++;
          }

          return number.toFixed(2).replace(/\.0+$/, '') + suffixes[suffixIndex];
        },
        style: {
          colors: ["#D4D4D4"]
        }
      }
    },
    grid: {
      show: false
    },
    legend: {
      position: 'top',
      horizontalAlign: 'right',
      offsetX: 40,
      enabled: false,
      markers: {
        strokeColor: '#D4D4D4',
      }
    }
    //   tooltip: {
    //     x: {
    //       format: 'dd/MM/yy HH:mm'
    //     },
    //   },
  };

  return (
    <div id="chart">
      <Chart options={options} series={options.series} type="area" height={350} />
    </div>
  );
};

export default RevenueGraph;