import * as yup from 'yup';

const EMAIL = yup
    .string()
    .matches(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/, 'Enter a valid email')
    .required('Email is required')
    .max(150, 'Email must be maximum 150 characters');

const PHONE_NUMBER = (name) =>
    yup
        .string()
        .matches(/^(\+\d{1,3}[- ]?)?\d{10}$/, `Invalid ${name}`)
        .min(10, `${name} should be minimum 10 digits`)
        .max(16, `${name} should be maximum 16 digits`)
        .required(`${name} is required`)
        .nullable();

const ALT_PHONE_NUMBER = (name) =>
    yup
        .string()
        .matches(/^(\+\d{1,3}[- ]?)?\d{10}$/, `Invalid ${name}`)
        .min(10, `${name} should be minimum 10 digits`)
        .max(16, `${name} should be maximum 16 digits`)
        .required(`${name} is required`)
        .nullable()
        .notOneOf([yup.ref('phone'), null], 'Alternate phone number must be different from the phone number');

const PASSWORD = yup
    .string()
    .min(8, 'Password should be of minimum 8 characters length')
    .max(18, 'Password should not contain more than 18 characters ')
    .required('Password is required');

const CONFIRM_PASSWORD = yup
    .string()
    .required('Confirm password is required')
    .min(8, 'Confirm password must be minimum 8 characters')
    .max(18, 'Confirm password must be maximum 18 characters ')
    .oneOf([yup.ref('password'), null], 'Both passwords must match');

export const EmailValidator = yup.object({
    email: EMAIL
});

export const LoginValidator = yup.object({
    email: EMAIL,
    password: PASSWORD
});

export const SignupValidator = yup.object({
    first_name: yup
        .string()
        .matches(/^(?!^\s*$)^[\w\s]*$/, 'Please enter valid first name')
        .required('First Name is required'),
    last_name: yup
        .string()
        .matches(/^(?!^\s*$)^[\w\s]*$/, 'Please enter valid last name')
        .required('Last Name is required'),
    mobile: PHONE_NUMBER('Phone Number'),
    email: EMAIL,
    password: PASSWORD,
    // passwordConfirm: CONFIRM_PASSWORD,
    city: yup
            .string()
            .matches(/^(?!^\s*$)^[\w\s]*$/, 'Please enter valid city')
            .required('City is required'),
});

export const ChangePasswordValidator = yup.object({
    password: PASSWORD,
    passwordConfirm: CONFIRM_PASSWORD
});

export const ShopifyConnectorValidator = yup.object({
    store_id: yup
        .string()
        // .matches('Please enter valid Store Id')
        .required('Store Id is required'),
    module_name: yup
        .string()
        // .matches(/^(?!^\s*$)^[\w\s]*$/, 'Please enter valid Store Name')
        .required('Store Name is required'),
    api_key: yup
        .string()
        // .matches(/^(?!^\s*$)^[\w\s]*$/, 'Please enter valid Api Key')
        .required('Api Key is required'),
    access_token: yup
        .string()
        // .matches(/^(?!^\s*$)^[\w\s]*$/, 'Please enter valid Access Token')
        .required('Access Token is required'),
    api_password: yup
        .string()
        // .matches(/^(?!^\s*$)^[\w\s]*$/, 'Please enter valid Api Password')
        .required('Api Password is required')
});
