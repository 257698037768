import { styled } from '@mui/material/styles';


export const StyledRecentSales = styled('div')(() => ({
    '.card':{
        padding: '0px 10px 0px 6px',
        maxHeight: '600px',
        overflowY: 'auto'
    },
    '.bodyTxt':{
        fontSize: '12px',
        color: '#8A8A8A',
        '.innerTxt':{
            fontSize: '15px',
            fontWeight: '700',
            color: '#06BF61'
        }
    },
    '.bodyRight':{
        textAlign: 'right',
        '.MuiSvgIcon-root':{
            marginRight: '10px',
            fontSize: '10px'
        },
        '.sideTxt':{
            fontSize: '14px',
            color: '#8A8A8A'
        },
    }
}));