import { useTheme } from '@mui/material/styles';
import SVGContainer from './SVGContainer';

function CloseIcon({ width = 26, height = 22, color: iconColor, ...restProps }) {
    const theme = useTheme();
    const color = iconColor || theme.colors.color.primary;
    return (
        <SVGContainer {...restProps}>
            <svg width={width} height={height} color={color} viewBox="0 0 26 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g id="Group 1000000934">
                    <path id="Line 103" d="M2 1.63281L24 21" stroke={color} strokeWidth="2" strokeLinecap="round" />
                    <path id="Line 104" d="M24 1.63281L2 21" stroke={color} strokeWidth="2" strokeLinecap="round" />
                </g>
            </svg>
        </SVGContainer>
    );
}

export default CloseIcon;