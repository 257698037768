import BaseService from './BaseService';
import Http from '../Http';

export default class ItemsService extends BaseService {
    static controllerArray = [];


    static getItemPerformance(params = {}, signal) {
        const controller = new AbortController();
        this.controllerArray.push(controller);

        return new Promise((resolve, reject) => {
            Http.get(`/app/item-performance`, { params }, {
                signal: signal || controller?.signal
            })
                .then((resp) => {
                    return resolve(resp.data);
                })
                .catch((err) => {
                    return reject(err);
                });
        });
    }
}
