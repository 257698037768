import axios from 'axios';
import BaseService from './BaseService';

export default class PredictionService extends BaseService {
    static controllerArray = [];

    static dataList() {
        return axios.get(`/api/data/list`);
    }

    static dataById(params = {}) {
        return axios.get(`/api/data/${params.id}`);
    }

    static uploadData(params = {}) {
        return axios.post(`/api/data/upload`, params);
    }

    static getPrediction1ByData(params = {}) {
        return axios.get(`/api/data/${params.dataId}/trend-data`);
    }

    static getPrediction2ByData(params = {}) {
        return axios.get(`/api/data/${params.dataId}/predicted-data`, { params }.data);
    }

    static postPredictedData(params = {}) {
        return axios.post(`/api/data/${params.id}/predicted-data`, params.data);
    }

    static postShowPredictedData(params = {}) {
        //    console.log('param',{ params })
        return axios.post(`/api/data/${params.id}/show-predicted-data`, params.data);
    }

    static storePrediction(params = {}) {
        return axios.post(`/api/prediction/${params.id}/store`, params.data);
    }

    static getPredictionList() {
        return axios.get(`/api/prediction/list`);
    }

    static getPredictionById(params = {}) {
        return axios.get(`/api/prediction/${params.id}`);
    }

    static getValidationsList(params = {}) {
        return axios.get(`/api/validation/list`);
    }

    static getValidationResultById(params = {}) {
        return axios.get(`/api/validation/${params.modelId}`);
    }

    static deleteValidationResult(params = {}) {
        return axios.delete(`/api/validation/${params.id}`);
    }

    static getModelTrainedCount(params = {}) {
        return axios.get(`/api/validation/getModelTrainedCount`);
    }

    static getAvgModelAccuracy(params = {}) {
        return axios.get(`/api/validation/getAvgModelAccuracy`);
    }

    static ongoingModelTraining(params = {}) {
        return axios.get(`/api/validation/ongoingModelTraining`);
    }

    static countDataUsed(params = {}) {
        return axios.get(`/api/validation/countDataUsed`);
    }
}
