import {
    Avatar,
    Box,
    Button,
    Paper,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
    Pagination
} from '@mui/material';
import { useEffect, useState } from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom';
import MuiLink from '../../Components/MuiLink/MuiLink';
import { BackArrowIcon } from '../../Components/Icons';
import FilterButton from '../../Components/Buttons/FilterButton';
import PredictionService from '../../Services/PredictionService';
import { useParams } from 'react-router-dom';
import AILoading from '../../Components/AILoading';
import { getFormat } from '../../Shared';

const Data1 = () => {
    const { id } = useParams();
    const [dbcolumn, setDbColumn] = useState(null);
    const [dbRows, setDbRows] = useState(null);
    const [pagination, setPagination] = useState(1);
    const [data, setData] = useState(null);
    const [dataName, setDateName] = useState(null);
    const [maxPagination, setMaxPagination] = useState(1);
    const [isTableData, setIsTableData] = useState(false);
    const paginationPerPage = 20;

    const splitIntoPagination = () => {
        const startingRange = (pagination - 1) * paginationPerPage;
        const endingRange = startingRange + paginationPerPage;
        const paginationData = dbRows && dbRows.slice(startingRange, endingRange);
        setData(paginationData);
    };

    const getMaxPagination = (data1 = []) => {
        const paginationPerPage = 20;
        const totalPages = Math.ceil(data1.length / paginationPerPage);
        return totalPages;
    };

    const selectPagination = (e, pageNum) => {
        setPagination(pageNum);
    };

    const applyFilter = () => {
        setPagination(1);
        if (dbRows) {
            setMaxPagination(getMaxPagination(dbRows));
            splitIntoPagination();
        }
    };

    function isValidDate(dateStr) {
        const date = new Date(dateStr);
        return !isNaN(date) && dateStr !== '' && date.toString() !== 'Invalid Date';
    }

    useEffect(() => {
        setIsTableData(true);
        PredictionService.dataById({ id })
            .then((res) => {
                if (res?.data?.data?.data) {
                    setDateName(res.data.data.name);
                    const { column, rows } = res.data.data.data;
                    setDbColumn(column);
                    let hasDateTime = false;

                    for (const row of rows) {
                        if (isValidDate(row.date) && getFormat(row.date).includes('HH:mm:ss')) {
                            hasDateTime = true;
                            break;
                        }
                    }
                    let convertedDataRows = rows.map((row) => {
                        let transformedRow = { ...row };
                        if (hasDateTime) {
                            const format = 'YYYY-MM-DD HH:mm:ss';
                            const dateUTC = moment.utc(row.date, format);
                            transformedRow.date = isValidDate(row.date) ? dateUTC.format(format) : row.date;
                        } else {
                            const format = 'YYYY-MM-DD';
                            // Use date-only formatting
                            transformedRow.date = isValidDate(row.date)
                                ? moment.utc(row.date).format(format)
                                : row.date;
                        }

                        for (const prop in transformedRow) {
                            if (
                                transformedRow.hasOwnProperty(prop) &&
                                !isNaN(transformedRow[prop]) &&
                                typeof transformedRow[prop] === 'number'
                            ) {
                                transformedRow[prop] = parseFloat(transformedRow[prop].toFixed(2));
                            }
                        }

                        return transformedRow;
                    });
                    setDbRows(convertedDataRows);
                    setIsTableData(false);
                }
            })
            .catch((err) => console.log(err));
    }, []);

    useEffect(() => {
        applyFilter();
    }, [dbRows]);

    useEffect(() => {
        splitIntoPagination();
    }, [pagination]);

    return (
        <>
            <div className="d-flex jcsb mb-m">
                <Stack direction="row" spacing={2}>
                    <Box>
                        <Typography className="capitalize" variant="h4" margin={'0.37rem 0'}>
                            {dataName}
                        </Typography>
                    </Box>
                </Stack>
            </div>

            <TableContainer component={Paper} sx={{ marginBottom: 10, borderRadius: 3 }}>
                <Box sx={{ margin: '0 1.25rem' }}>
                    {isTableData ? (
                        <AILoading />
                    ) : (
                        <Table aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    {dbcolumn &&
                                        dbcolumn.map((column) => (
                                            <TableCell key={column.name}>
                                                <Stack direction="row">
                                                    <Typography variant="h6">{column.name.toUpperCase()}</Typography>
                                                </Stack>
                                            </TableCell>
                                        ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {data &&
                                    data.map((row, index) => (
                                        <TableRow key={index}>
                                            {dbcolumn &&
                                                dbcolumn.map((column, colIndex) => (
                                                    <TableCell key={colIndex} component="td" scope="row">
                                                        {row[column.name]}
                                                    </TableCell>
                                                ))}
                                        </TableRow>
                                    ))}
                            </TableBody>
                        </Table>
                    )}
                    <div className="d-flex jcfe">
                        {maxPagination > 1 && (
                            <div className="pagination-part">
                                <div>
                                    <Pagination count={maxPagination} page={pagination} onChange={selectPagination} />
                                </div>
                            </div>
                        )}
                    </div>
                </Box>
            </TableContainer>
        </>
    );
};

export default Data1;
