import BaseService from './BaseService';
import Http from '../Http';

export default class SalesService extends BaseService {
    static controllerArray = [];

    static getTotalSales(params = {}, signal) {
        const controller = new AbortController();
        this.controllerArray.push(controller);

        return new Promise((resolve, reject) => {
            Http.get(`/app/total-sale`, { params }, {
                signal: signal || controller?.signal
            })
                .then((resp) => {
                    return resolve(resp.data);
                })
                .catch((err) => {
                    return reject(err);
                });
        });
    }

    static getMonthlyRevenue(params = {}, signal) {
        const controller = new AbortController();
        this.controllerArray.push(controller);

        return new Promise((resolve, reject) => {
            Http.get(`/app/monthly-revenue`, { params }, {
                signal: signal || controller?.signal
            })
                .then((resp) => {
                    return resolve(resp.data);
                })
                .catch((err) => {
                    return reject(err);
                });
        });
    }


    static getSalesPredictions(params = {}, signal) {
        const controller = new AbortController();
        this.controllerArray.push(controller);

        return new Promise((resolve, reject) => {
            Http.get(`/app/predicted-sales`, { params }, {
                signal: signal || controller?.signal
            })
                .then((resp) => {
                    return resolve(resp.data);
                })
                .catch((err) => {
                    return reject(err);
                });
        });
    }

    static getSalesProducts(params = {}, signal) {
        const controller = new AbortController();
        this.controllerArray.push(controller);

        return new Promise((resolve, reject) => {
            Http.get(`/app/products`, { params }, {
                signal: signal || controller?.signal
            })
                .then((resp) => {
                    return resolve(resp.data);
                })
                .catch((err) => {
                    return reject(err);
                });
        });
    }

    static getSalesStore(params = {}, signal) {
        const controller = new AbortController();
        this.controllerArray.push(controller);

        return new Promise((resolve, reject) => {
            Http.get(`/app/stores`, { params }, {
                signal: signal || controller?.signal
            })
                .then((resp) => {
                    return resolve(resp.data);
                })
                .catch((err) => {
                    return reject(err);
                });
        });
    }

    static uploadFile(params = {}, signal) {
        const controller = new AbortController();
        this.controllerArray.push(controller);

        return Http.post(`/app/sales-upload`, params, {
            signal: signal || controller?.signal
        });
    }

    static uploadReviewFile(params = {}, signal) {
        const controller = new AbortController();
        this.controllerArray.push(controller);

        return Http.post(`/app/reviews-upload`, params, {
            signal: signal || controller?.signal
        });
    }

    static uploadProductsFile(params = {}, signal) {
        const controller = new AbortController();
        this.controllerArray.push(controller);

        return Http.post(`/app/product-upload`, params, {
            signal: signal || controller?.signal
        });
    }

    static uploadStoresFile(params = {}, signal) {
        const controller = new AbortController();
        this.controllerArray.push(controller);

        return Http.post(`/app/store-upload`, params, {
            signal: signal || controller?.signal
        });
    }


}
