import React, { lazy, Suspense, useState } from 'react';
import { HashRouter, Routes, Route, Navigate, useLocation } from 'react-router-dom';
import Dashboard from './Pages/Restricted/Dashboard/Dashboard';
import { useSelector } from 'react-redux'
// import Prediction from './Components/PredictionSteps/Prediction';
import Loading from './Components/Loading';
import Data1 from './Pages/Restricted/Data1';
import PredictionTrainTest from './Pages/Restricted/PredictionTrainTest';
import Subscription from './Pages/Restricted/User/Subscription';
import Users from './Pages/Restricted/User/Users';
import Profile from './Pages/Restricted/User/Profile';

const AuthLayout = lazy(() => import('./Layouts/AuthLayout/AuthLayout'));
const DashboardLayout = lazy(() => import('./Layouts/DashboardLayout/DashboardLayout'));
const Login = lazy(() => import('./Pages/Public/Login'));
const SignUp = lazy(() => import('./Pages/Public/SignUp'));
const EmailVerification = lazy(() => import('./Pages/Public/EmailVerification'));
const SignUpPassword = lazy(() => import('./Pages/Public/SignUpPassword'));
const ForgotPassword = lazy(() => import('./Pages/Public/ForgotPassword'));
const ResetPassword = lazy(() => import('./Pages/Public/ResetPassword'));
const NotFound = lazy(() => import('./Pages/Public/NotFound'));

const Prediction1 = lazy(() => import('./Pages/Restricted/Prediction1'));
const UploadAndValidate = lazy(() => import('./Pages/Restricted/UploadAndValidate/UploadAndValidate'));
const ScenarioPlan = lazy(() => import('./Pages/Restricted/ScenarioPlan/ScenarioPlan'));
const ScenarioPlanDetails = lazy(() => import('./Pages/Restricted/ScenarioPlan/ScenarioPlanDetails'));
const PredictionById = lazy(() => import('./Pages/Restricted/PredictionById/PredictionById'));
const CreatePrediction = lazy(() => import('./Pages/Restricted/CreatePrediction/CreateFuturePrediction'));

const ShopifyConnector = lazy(() => import('./Pages/Restricted/ShopifyConnector/ShopifyConnector'));
const PriceSuggestions = lazy(() => import('./Pages/Restricted/PriceSuggestions/PriceSuggestions'));
const SalesPrediction = lazy(() => import('./Pages/Restricted/SalesPrediction/SalesPrediction'));
const SentimentCheck = lazy(() => import('./Pages/Restricted/SentimentCheck/SentimentCheck'));
const Test = lazy(()=> import('./Pages/Restricted/Dashboard/Test/Test'));
function RequireAuth({ isAuthenticated }) {
  let location = useLocation();

  if (!isAuthenticated) {
    return <Navigate to="/login" />;
  }

  return (
    <Suspense fallback={<Loading />}>
      <DashboardLayout  />
    </Suspense>
  );
}

function StrictlyNotRequireAuth({ isAuthenticated }) {
  let location = useLocation();
  if (isAuthenticated) {
    return <Navigate to="/dashboard" state={{ from: location }} />;
  }

  return (
    <Suspense fallback={<Loading />}>
      <AuthLayout />
    </Suspense>
  );
}

function RoutesComponent() {
  const { isAuthenticated } = useSelector((store) => store.auth);

  return (
    <HashRouter>
      <Routes>
        <Route element={<StrictlyNotRequireAuth isAuthenticated={isAuthenticated} />}>
          <Route path="/login" exact={true} name='Login' element={<Suspense fallback={<Loading />}><Login /></Suspense>} />
          <Route path="/signup" exact={true} name='SignUp' element={<Suspense fallback={<Loading />}><SignUp /></Suspense>} />
          <Route path="/email-verification" exact={true} name='EmailVerification' element={<Suspense fallback={<Loading />}><EmailVerification /></Suspense>} />
          <Route path="/signup/:id" exact={true} name='SignUpPassword' element={<Suspense fallback={<Loading />}><SignUpPassword /></Suspense>} />
          <Route path="/forgot-password" exact={true} name='Login' element={<Suspense fallback={<Loading />}><ForgotPassword /></Suspense>} />
          <Route path="/reset-password/:id" exact={true} name='ResetPassword' element={<Suspense fallback={<Loading />}><ResetPassword /></Suspense>} />
        </Route>
        <Route element={<RequireAuth isAuthenticated={isAuthenticated} />}>
          <Route path="/dashboard" exact={true} name='DashboardHome' element={<Suspense fallback={<Loading />}><Dashboard /></Suspense>} />
          <Route path="/subscriptions" exact={true} name='DashboardHome' element={<Suspense fallback={<Loading />}><Subscription/></Suspense>} />
          <Route path="/users" exact={true} name='DashboardHome' element={<Suspense fallback={<Loading />}><Users/></Suspense>} />
          <Route path="/profile" exact={true} name='DashboardHome' element={<Suspense fallback={<Loading />}><Profile /></Suspense>} />

          <Route path="/upload-and-validate/" exact={true} name='Create-Prediction' element={<Suspense fallback={<Loading />}><UploadAndValidate /></Suspense>} />
          <Route path="/prediction1/:dataId" exact={true} name='Prediction1' element={<Suspense fallback={<Loading />}><Prediction1 /></Suspense>} />
          <Route path="/prediction-details/:id" exact={true} name='Prediction-Details' element={<Suspense fallback={<Loading />}><PredictionById /></Suspense>} />
          <Route path="/data/:id" exact={true} name='DashboardHome' element={<Suspense fallback={'Loading'}><Data1 /></Suspense>} />
          <Route path="/prediction/train-test" exact={true} name='DashboardHome' element={<Suspense fallback={'Loading'}><PredictionTrainTest /></Suspense>} />
          <Route path="/scenario-plan" exact={true} name='ScenarioPlan' element={<Suspense fallback={<Loading />}><ScenarioPlan /></Suspense>} />
          <Route path="/scenario-plan-details/:id" exact={true} name='ScenarioPlanDetails' element={<Suspense fallback={<Loading />}><ScenarioPlanDetails /></Suspense>} />
          <Route path="/create-prediction/:modelId" exact={true} name='CreatePrediction' element={<Suspense fallback={<Loading />}><CreatePrediction /></Suspense>} />

          <Route path="/sales-record" exact={true} name='Sales Record' element={<Suspense fallback={<Loading />}><ShopifyConnector /></Suspense>} />
          <Route path="/sales-prediction" exact={true} name='SalesPrediction' element={<Suspense fallback={<Loading />}><SalesPrediction /></Suspense>} />
          <Route path="/price-suggestions" exact={true} name='PriceSuggestions' element={<Suspense fallback={<Loading />}><PriceSuggestions /></Suspense>} />
          <Route path="/sentiment-check" exact={true} name='sentimentCheck' element={<Suspense fallback={<Loading />}><SentimentCheck /></Suspense>} />
          <Route path="/test" exact={true} name='sentimentCheck' element={<Suspense fallback={<Loading />}><Test /></Suspense>} />
        </Route>
        <Route path="*" exact={true} name='NotFoundPage' element={<Suspense fallback={<Loading />}><NotFound /></Suspense>} />
        <Route path="" exact={true} name='RedirctToDashboard' element={<Navigate to="/dashboard" replace />} />
      </Routes >
    </HashRouter >
  )
}

export default RoutesComponent;
