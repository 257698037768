import { SET_DATA_LIST_COUNT, SET_VALIDATION_LIST_COUNT } from '../Action';

const state = { data_list_count: 0, validation_list_count: 0 };

const DataListReducer = (dataListState = { ...state }, action) => {
    switch (action.type) {
        case SET_DATA_LIST_COUNT:
            return { ...state, data_list_count: action.payload };

        case SET_VALIDATION_LIST_COUNT:
            return { ...dataListState, validation_list_count: action.payload };

        default:
            return dataListState;
    }
};

export default DataListReducer;