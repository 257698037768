import React from "react";
import { StyledStoreSales } from "./style";
import { Box, Button, Card, CardContent, CardHeader, Grid, IconButton, Typography } from "@mui/material";
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import CircleIcon from '@mui/icons-material/Circle';
import RevenueGraph from "../Graphs/RevenueGraph";
import DataTable from "../DataTable/DataTable";

const columns = [
    { id: 'name', label: 'Name' },
    { id: 'date', label: 'Date' },
    { id: 'lastupdated', label: 'Last Updated' },
    { id: 'sales', label: 'Sales' },
    { id: 'profitpercentage', label: 'Profit Percent' },
];

const data = [
    { id: 1, name: "store-1", date: "Wed, 2 jan", lastupdated: "12.34 AM", sales: "$124.30", profitpercentage: "70%" },
    { id: 2, name: "store-2", date: "Wed, 2 jan", lastupdated: "12.34 AM", sales: "$124.30",profitpercentage: "70%" },
    { id: 3, name: "store-3", date: "Wed, 2 jan", lastupdated: "12.34 AM", sales: "$124.30",profitpercentage: "70%" },
    { id: 4, name: "store-4", date: "Wed, 2 jan", lastupdated: "12.34 AM", sales: "$124.30",profitpercentage: "70%" },
    { id: 5, name: "store-5", date: "Wed, 2 jan", lastupdated: "12.34 AM", sales: "$124.30",profitpercentage: "70%" },
    { id: 6, name: "store-6", date: "Wed, 2 jan", lastupdated: "12.34 AM", sales: "$124.30",profitpercentage: "70%" },
    { id: 7, name: "store-7", date: "Wed, 2 jan", lastupdated: "12.34 AM", sales: "$124.30",profitpercentage: "70%" },
    { id: 8, name: "store-8", date: "Wed, 2 jan", lastupdated: "12.34 AM", sales: "$124.30",profitpercentage: "70%" },
    { id: 9, name: "store-9", date: "Wed, 2 jan", lastupdated: "12.34 AM", sales: "$124.30",profitpercentage: "70%" },
];

export default function StoreSales({ header }){
    return(
        <>
            <StyledStoreSales>
                <Card className="card">
                    <CardHeader 
                        title={header}
                        action={
                            <Button>Filter</Button>
                        }
                    />
                    <CardContent>
                        <DataTable columns={columns} data={data} scrollable={false}/>
                    </CardContent>
                </Card>
            </StyledStoreSales>
        </>
    )
}