import React from 'react';
import { Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

const StyledContainer = styled('div')`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 12.5rem;
`;

const AILoading = () => {
    return (
        <StyledContainer>
          <img src="/loading.gif" alt="Loading" style={{ width: 150, height: 150, marginTop: 16 }} />
            <Typography variant="subtitle1" sx={{ mb: 2 }}>
                Hold on, The AI is working with your data
            </Typography>
        </StyledContainer>
    );
};

export default AILoading;