import { useTheme } from '@emotion/react';
import React, { useEffect,useRef } from 'react';
import Chart from 'react-apexcharts';
import './styles.css';

const GradientGraph = ({ graphData }) => {
    const theme = useTheme();
    const categoryColor = "#D4D4D4"; 
    console.log("graphData",graphData);
    // const top_selling_items = graphData.top_selling_items;
    // console.log("top_selling_items",top_selling_items);
    // const product = [];
    // const total_revenue = [];
    // (top_selling_items || []).forEach(gd => {
    //   product.push(gd.product);
    //   total_revenue.push(gd.total_revenue)
    // });
    // const topSellingItems = graphData.top_selling_items;
    // console.log("topSellingItems",topSellingItems);
    // const seriesData = topSellingItems.map(item => parseFloat(item.total_revenue));
    // console.log("seriesData",seriesData);
    // const labelsData = topSellingItems.map(item => item.product);
    // console.log("labelsData",labelsData);
    // [44, 55, 41, 17, 15]
    // ['Item-1', 'Item-2', 'Item-3', 'Item-4', 'Item-5']
    const options = {
        // series: [44, 55, 41, 17, 15],
        series: graphData.top_selling_items,
        labels: ['Item-1', 'Item-2', 'Item-3', 'Item-4', 'Item-5'],
        chart: {
        width: 480,
        type: 'donut',
      },
      plotOptions: {
        pie: {
          startAngle: -90,
          endAngle: 270,
          donut: {
            size: '85%',
            labels: {
                show: false,
                total: {
                  show: true,
                  label: 'Hello',
                  formatter: function () { 
                    return '<div class="total-label">Text you want</div>'; 
                  },
                  color: '#000', // Color of the total label
                  fontSize: '10px', // Font size of the total label
                  fontFamily: 'Helvetica, Arial, sans-serif',
                  fontWeight: 'bold', // Font weight of the total label
                  offsetY: 0,
                  margin: '10px'
                },
                value: {
                    show: true,
                    fontSize: '16px',
                    fontFamily: 'Helvetica, Arial, sans-serif',
                    fontWeight: 400,
                    color: undefined,
                    offsetY: 16,
                    formatter: function (val) {
                        return val
                    }
                },
                name: {
                    show: true,
                    fontSize: '22px',
                    fontFamily: 'Helvetica, Arial, sans-serif',
                    fontWeight: 600,
                    color: undefined,
                    offsetY: -10,
                    formatter: function (val) {
                        return val
                    }
                },
            }
          }
        }
      },
      dataLabels: {
        enabled: false
      },
      fill: {
        type: 'gradient',
      },
      legend: {
        formatter: function(val, opts) {
          return val + " - " + opts.w.globals.series[opts.seriesIndex] + " % "
        },
        offsetY: -12,
        itemMargin: {
            vertical: 2
        },
        labels:{
          colors: Array(12).fill(categoryColor)
        }
      },
      colors:["#4C71F0","#7BB6DB","#23CC8E","#4D73F3","#F79319"],
      stroke: {
        show: false
      },
      responsive: [{
        breakpoint: 1024,
        options: {
          chart: {
            width: 350,
            height: 800
          },
          legend: {
            position: 'bottom'
          }
        }
      }],
      };
    
      return (
        <div id="chart">
            <Chart options={options} series={options.series} type="donut" height={450} />
        </div>
      );
    };

export default GradientGraph;