import { combineReducers } from 'redux';
import AuthReducers from './AuthReducers';
import PredictionReducers from './PredictionReducers';
import UiReducers from './UiReducers';
import ModelStatusReducer from './ModelStatusReducer';
import DataListReducer from './dataReducer';
const Reducers = combineReducers({
    auth: AuthReducers,
    predictions: PredictionReducers,
    ui: UiReducers,
    modelStatus: ModelStatusReducer,
    dataList: DataListReducer
});

export default Reducers;
