import { useTheme } from '@mui/material/styles';
import SVGContainer from './SVGContainer';

function TransationIcon({ width = 25, height = 25, color: iconColor, ...restProps }) {
    const theme = useTheme();
    const color = iconColor || theme.colors.color.primary;
    return (
        <SVGContainer {...restProps}>
            <svg
                width={width} height={height}
                color={color}
                viewBox="0 0 30 30"
            >
                <g fill='currentColor'>
                    <path d="M22.5 19c-1.375 0-2.5 1.125-2.5 2.5s1.125 2.5 2.5 2.5c.574 0 1.06-.246 1.5-.6.44.354.926.6 1.5.6 1.375 0 2.5-1.125 2.5-2.5S26.875 19 25.5 19c-.574 0-1.06.24-1.5.595-.44-.355-.926-.594-1.5-.595zm0 1c.43 0 .84.186 1.125.51.2.225.55.225.75 0 .285-.324.694-.51 1.125-.51.834 0 1.5.666 1.5 1.5s-.666 1.5-1.5 1.5a1.5 1.5 0 01-1.125-.51.501.501 0 00-.75 0c-.285.324-.694.51-1.125.51-.834 0-1.5-.666-1.5-1.5s.666-1.5 1.5-1.5zm-7 1h2a.499.499 0 110 1h-2a.499.499 0 110-1zm-4 0h2a.499.499 0 110 1h-2a.499.499 0 110-1zm-4 0h2a.499.499 0 110 1h-2a.499.499 0 110-1zm7-5h12a.499.499 0 110 1h-12a.499.499 0 110-1zm5-3h7a.499.499 0 110 1h-7a.499.499 0 110-1zm-5 0h3a.499.499 0 110 1h-3a.499.499 0 110-1zm-7-1a.5.5 0 00-.5.5v5a.5.5 0 00.5.5h4a.5.5 0 00.5-.5v-5a.5.5 0 00-.5-.5zm.5 1h3v4H8zm-1.5-3c-.822 0-1.5.678-1.5 1.5v13c0 .822.678 1.5 1.5 1.5h22c.822 0 1.5-.678 1.5-1.5v-13c0-.822-.678-1.5-1.5-1.5zm0 1h22c.286 0 .5.214.5.5v13c0 .286-.214.5-.5.5h-22a.488.488 0 01-.5-.5v-13c0-.286.214-.5.5-.5zm-5-7C.678 4 0 4.678 0 5.5v13c0 .822.678 1.5 1.5 1.5h2c.672 0 .653-1 0-1h-2a.488.488 0 01-.5-.5V12h1.5a.499.499 0 100-1H1V8h24v.5c0 .672 1 .657 1 0v-3c0-.822-.678-1.5-1.5-1.5zm0 1h23c.286 0 .5.214.5.5V7H1V5.5c0-.286.214-.5.5-.5z"></path>
                </g>
            </svg>
        </SVGContainer>
    );
}

export default TransationIcon;