import { createTheme, responsiveFontSizes } from '@mui/material/styles';

const commonColor = {
    common: {
        white: '#ffffff',
        black: '#000000'
    },
    selectedColor: {
        primary: '#3959ED',
        secondary: '#A449FF'
    }
};

const dark = {
    ...commonColor,
    bodyBgColor: '#161616',
    componentBgColor: '#1D1D1F',
    navbarBgColor: '#1F2326',
    sidebarBtnBg: '#ffffff24',
    color: {
        primary: '#FFFFFF',
        secondary: '#F6F6F6'
    },
    invertColor: {
        primary: '#000000',
        secondary: '#2E2E2E'
    }
};

const light = {
    ...commonColor,
    bodyBgColor: '#F7F8FB',
    componentBgColor: '#FFFFFF',
    navbarBgColor: '#FFFFFF',
    // sidebarBtnBg: 'rgba(255, 255, 255, 0.14)',
    sidebarBtnBg: '#70656524',
    color: {
        primary: '#000000',
        secondary: '#5E5E5E'
    },
    invertColor: {
        primary: '#FFFFFF',
        secondary: '#d8d8d8'
    }
};

const createPrimaryTheme = (type = 'light') => {
    const themeColors = type === 'light' ? { ...light } : { ...dark };
    // console.log(themeColors)
    let theme = createTheme({
        colors: { ...themeColors },
        palette: {
            mode: type,
            primary: {
                main: themeColors.color.primary
            },
            secondary: {
                main: themeColors.color.secondary
            },
            error: {
                main: '#EA3A3D'
            },
            success: {
                main: '#1AD598'
            },
            warning: {
                main: '#F9B959'
            },
            background: {
                paper: themeColors.componentBgColor
            },
            text: {
                primary: themeColors.color.primary,
                secondary: themeColors.color.secondary
            }
        },
        typography: {
            fontFamily: '"Nunito", sans-serif',
            h1: {
                fontSize: '2rem',
                fontWeight: '600'
            },
            h2: {
                fontSize: '1.8rem',
                fontWeight: '600'
            },
            h3: {
                fontSize: '1.6rem',
                fontWeight: '600'
            },
            h4: {
                fontSize: '1.4rem',
                fontWeight: '600'
            },
            h5: {
                fontSize: '1.2rem',
                fontWeight: '600'
            },
            h6: {
                fontSize: '1rem',
                fontWeight: '600'
            }
        },
        breakpoints: {
            values: {
                xs: 0,
                sm: 576,
                md: 768,
                lg: 992,
                xl: 1200,
                xxl: 1400,
                sz_1600: 1600,
                sz_1800: 1800,
                sz_1900: 1900
            }
        },
        components: {
            MuiPaper: {
                styleOverrides: {
                    root: {
                        backgroundImage: 'unset'
                    }
                }
            },

            MuiButton: {
                defaultProps: {
                    variant: 'contained'
                },

                styleOverrides: {
                    root: {
                        '&&&': {
                            boxShadow: 'unset',
                            textTransform: 'capitalize',

                            '*': {
                                color: 'inherit'
                            }
                        }
                    },

                    contained: {
                        '&&&': {
                            '&.unround': {
                                borderRadius: '0.5rem'
                            },
                            head: {
                                th: {
                                    whiteSpace: 'nowrap'
                                }
                            },
                            '&:not(.unround)': {
                                borderRadius: '30.31rem'
                            },

                            '&:not(.white-button), &:not(.white-button) *': {
                                color: themeColors.invertColor.primary
                            }
                        }
                    },

                    outlined: {
                        '&&&': {
                            borderWidth: '0.12rem'
                        }
                    },

                    fullWidth: {
                        '&&&': {
                            height: '3.45rem'
                        }
                    },

                    text: {
                        '&&&': {
                            color: themeColors.color.primary,
                            padding: 0,
                            backgroundColor: 'transparent',
                            minWidth: 'unset',
                            borderRadius: 0,

                            '&.text-color': {
                                color: themeColors.color.secondary
                            },

                            '.MuiTouchRipple-root': {
                                display: 'none'
                            },

                            '&:hover': {
                                filter: 'saturate(1.8)'
                            }
                        }
                    },

                    sizeLarge: {
                        '&&&': {
                            height: '3.45rem'
                        }
                    }
                }
            },

            MuiIconButton: {
                styleOverrides: {
                    root: {
                        '&&&': {
                            padding: 0
                        }
                    }
                }
            },

            MuiTableRow: {
                styleOverrides: {
                    root: {
                        '&:last-child td': {
                            borderBottom: 'none'
                        }
                    },
                    head: {
                        th: {
                            whiteSpace: 'nowrap'
                        }
                    }
                }
            },

            MuiTableCell: {
                styleOverrides: {
                    root: {
                        borderColor: themeColors.color.primary + '29'
                    }
                }
            },

            MuiDivider: {
                styleOverrides: {
                    root: {
                        borderColor: themeColors.color.primary + '29'
                    }
                }
            },

            MuiLink: {
                defaultProps: {
                    underline: 'none'
                },
                styleOverrides: {
                    root: {
                        '&&&': {
                            fontWeight: 'bold',
                            display: 'inline-flex',
                            alignItems: 'center',

                            '&.text-color': {
                                color: themeColors.color.secondary
                            },

                            '&:hover': {
                                filter: 'saturate(1.8)'
                            }
                        }
                    }
                }
            },

            MuiInputBase: {
                styleOverrides: {
                    root: {
                        '&&&': {
                            backgroundColor: themeColors.componentBgColor,
                            borderRadius: '0.62rem'
                        }
                    }
                }
            },

            MuiTextField: {
                defaultProps: {
                    fullWidth: true,
                    color: 'secondary'
                },

                styleOverrides: {
                    root: {
                        '&&&': {
                            backgroundColor: themeColors.componentBgColor,
                            borderRadius: '0.62rem',
                            '.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                boxShadow: `0 0 0.31rem rgba(33, 133, 202, 0.05)`
                            },

                            '&.h-sm': {
                                input: {
                                    height: '2.80rem',
                                    padding: '0 0.9rem'
                                }
                            }
                        }
                    }
                }
            },

            MuiFormGroup: {
                styleOverrides: {
                    root: {
                        marginBottom: '1.87rem',
                        position: 'relative',

                        '&.with-switch': {
                            marginBottom: '1.25rem'
                        },

                        '.Mui-error.MuiFormHelperText-root': {
                            position: 'absolute',
                            left: 0,
                            bottom: -25,
                            marginLeft: 0,

                            '&.otp-error': {
                                left: 15
                            }
                        }
                    }
                }
            },

            MuiTab: {
                styleOverrides: {
                    root: {
                        '&&&': {
                            paddingLeft: 0,
                            paddingRight: 0,
                            minWidth: 'unset',
                            color: themeColors.color.primary,
                            textTransform: 'capitalize',
                            '&:not(:last-child)': {
                                marginRight: '2.5rem'
                            }
                        }
                    }
                }
            },

            MuiCardHeader: {
                styleOverrides: {
                    root: {
                        '&&&': {
                            '@media(min-width: 1024px)': {
                                padding: '1.25rem'
                            },

                            '.MuiCardHeader-subheader': {
                                marginTop: '0.8rem'
                            }
                        }
                    }
                }
            },

            MuiCardContent: {
                styleOverrides: {
                    root: {
                        '&&&': {
                            '@media(min-width: 1024px)': {
                                padding: '1.25rem'
                            }
                        }
                    }
                }
            },

            MuiCard: {
                styleOverrides: {
                    root: {
                        '&&&': {
                            border: type === 'dark' ? 0 : '0.12rem solid rgba(192, 192, 192, 0.31)',
                            borderRadius: '0.62rem',
                            boxShadow: 'unset',

                            '.MuiCardHeader-root + .MuiCardContent-root': {
                                paddingTop: 0
                            }
                        }
                    }
                }
            },
            MuiChip: {
                styleOverrides: {
                    root: {
                        '&&&': {
                            color: themeColors.common.white,
                            backgroundColor: themeColors.selectedColor.primary,

                            '&.pending': {
                                backgroundColor: themeColors.selectedColor.secondary
                            },

                            '*': {
                                color: 'inherit'
                            }
                        }
                    }
                }
            }
        }
    });

    // console.log(theme);

    theme = responsiveFontSizes(theme);
    return theme;
};

export default createPrimaryTheme;
