import { Typography } from '@mui/material';

function Loading() {
    return (
        <div className="flex-center">
            <div className="flex-center" style={{ flexDirection: 'column' }}>
                <img src="/loading.gif" alt="Loading" style={{ width: 150, height: 150, marginTop: 16 }} />
                <Typography className="mt" variant="h1">
                    Loading...
                </Typography>
            </div>
        </div>
    );
}
export default Loading;
