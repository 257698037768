export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const SET_DATE_COLOUMN = 'SET_DATE_COLOUMN';
export const TOGGLE_THEME = 'TOGGLE_THEME';
export const SET_MODEL_TRAINED = 'SET_MODEL_TRAINED';
export const SET_ONGOING_MODEL_TRAINING = 'SET_ONGOING_MODEL_TRAINING';
export const SET_DATA_LIST_COUNT = 'SET_DATA_LIST_COUNT';
export const SET_VALIDATION_LIST_COUNT = 'SET_VALIDATION_LIST_COUNT';
export const REFRESH_SUCCESS = 'REFRESH_SUCCESS';

