import React from 'react';
import { Typography, Card, CardHeader, CardContent, CardActions, useTheme } from '@mui/material';
import ArrowIcon from '../Icons/ArrowIcon';
import PropTypes from 'prop-types';

/**
 * DashboardMetrics Component
 *
 * @component
 * @param {Object} props - The properties of the component.
 * @param {React.Component} props.header - The header Component.
 * @param {React.ReactNode} props.body - The body element or fragment.
 * @param {number} props.growthPercentage - The growth percentage as a number.
 * @param {string} props.lastMonth - data from the last month as a string of percentage or numeric value.
 * @returns {React.ReactNode} - The rendered DashboardMetrics component.
 *
 * @example
 * 
 * <DashboardMetrics
 *   header={<h2>Metrics</h2>}
 *   body={<p>Some metric details...</p>}
 *   growthPercentage={10.5 0r -5.5}
 *   lastMonth={21% or 15k}
 * />
 */

export default function DashboardMetrics({ header, body, growthPercentage, lastMonth, ...rest }) {
    const theme = useTheme();

    return (
        <Card {...rest}>
            <CardHeader component={header} />
            <CardContent>
                <Typography variant='h1'>
                    {body}
                </Typography>
            </CardContent>
            <CardActions>
                {/* <Typography className='d-flex aic ml'>
                    <ArrowIcon className='mr' face={growthPercentage > 0 ? 'up' : 'down'}
                        color={theme.palette[growthPercentage > 0 ? 'success' : 'error'].main} />
                    <span className='mr-h' style={{ color: theme.palette[growthPercentage > 0 ? 'success' : 'error'].main }}>{growthPercentage}%</span> <span className='mr-h'>VS</span> last month {lastMonth}
                </Typography> */}
            </CardActions>
        </Card>
    )
}

DashboardMetrics.propTypes = {
    header: PropTypes.elementType.isRequired,
    body: PropTypes.element.isRequired,
    growthPercentage: PropTypes.number.isRequired,
    lastMonth: PropTypes.string.isRequired,
};
