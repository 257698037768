import moment from 'moment'

function getFormat(datetime) {
  const parsedDatetime = moment(datetime, [
    'YYYY-MM-DD HH:mm:ss',
    'ddd, DD MMM YYYY HH:mm:ss [GMT]',
    // Add more formats as needed
  ]);

  if (parsedDatetime.isValid()) {
    if (parsedDatetime.get('hour') === 0 && parsedDatetime.get('minute') === 0 && parsedDatetime.get('second') === 0) {
      return 'YYYY-MM-DD';
    } else {
      return 'YYYY-MM-DD HH:mm:ss';
    }
  } else {
    return 'Invalid datetime format';
  }
}

export default getFormat;