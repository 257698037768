import { useTheme } from '@mui/material/styles';
import PropTypes from 'prop-types';
import SVGContainer from './SVGContainer';
import { classNames } from '../../Shared/classNames';

/**
 * AngleIcon Component - SVG icon representing an arrow angle.
 *
 * @component
 * @param {Object} props - The properties of the component.
 * @param {number} [props.width=20] - The width of the icon.
 * @param {number} [props.height=20] - The height of the icon.
 * @param {string} [props.color] - The color of the icon. If not provided, it uses the primary color from the theme.
 * @param { 'up'  | 'down' | 'left' | 'right'} [props.face] - The direction the arrow is facing.
 * @param {string} [props.className] - Additional class names to be applied to the SVGContainer.
 * @param {Object} restProps - Additional props to be spread on the SVGContainer.
 * @returns {JSX.Element} - The rendered AngleIcon component.
 *
 * @example
 *
 * <AngleIcon
 *   width={30}
 *   height={30}
 *   color="blue"
 *   face="left"
 *   className="custom-icon"
 *   onClick={handleClick}
 * />
 */

function AngleIcon({ width = 20, height = 20, color: iconColor, face = 'down', className = '', ...restProps }) {
   const theme = useTheme();
   const color = iconColor || theme.colors.color.primary;
   const faceIncludeInClassName = className.includes('face-');

   return (
      <SVGContainer {...restProps} className={classNames(className, {
         'face-up': !faceIncludeInClassName && face === 'up',
         'face-down': !faceIncludeInClassName && face === 'down',
         'face-left': !faceIncludeInClassName && face === 'left',
         'face-right': !faceIncludeInClassName && face === 'right'
      })}>
         {/* SVG path elements for the arrow icon */}
         <svg width={width} height={height} color={color} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M16.6402 8.26824L10.6402 13.2682C9.61589 14.1218 8.33552 12.5854 9.35982 11.7318L15.3598 6.7318C16.3841 5.87822 17.6645 7.41466 16.6402 8.26824Z" fill={color} />
            <path d="M4.64019 6.7318L10.6402 11.7318C11.6645 12.5854 10.3841 14.1218 9.35982 13.2682L3.35982 8.26824C2.33552 7.41466 3.61589 5.87822 4.64019 6.7318Z" fill={color} />
         </svg>
      </SVGContainer>
   );
}

AngleIcon.propTypes = {
   width: PropTypes.number,
   height: PropTypes.number,
   color: PropTypes.string,
   face: PropTypes.oneOf(['up', 'down', 'left', 'right']),
   className: PropTypes.string,
};

export default AngleIcon;
