import { Avatar, Box, Card, Container, Stack, TextField, Typography } from '@mui/material';
import MuiLink from '../../Components/MuiLink/MuiLink';
import { BackArrowIcon } from '../../Components/Icons';

const PredictionTrainTest = () => {
    return (
        <>
            <Stack direction="row" spacing={3}>
                {/* <Box alignSelf="center">
                    <Avatar>
                        <MuiLink to="/dashboard">
                            <BackArrowIcon width={20} />
                        </MuiLink>
                    </Avatar>
                </Box> */}
                <Box>
                    <Typography variant="h4">{'Prediction 1'}</Typography>
                    <Typography variant="subtitle1" color={'gray'}>
                        {'Predict with AI'}
                    </Typography>
                </Box>
            </Stack>
            <Box maxWidth={'50%'}>
                <Card borderRadius={3}>
                <Container>
    
                    <Box marginBottom={3} >
                    <Typography variant='h5'> Select train test Percentage</Typography>
                    <Stack direction='row'>
                    <TextField id="outlined-basic" label="45" variant="outlined"  sx={{width:'20%'}}/>
                    <TextField id="outlined-basic" label="45" variant="outlined" sx={{width:'20%'}}/>
                    </Stack>
                    </Box>

                    <Stack direction='row'>
                        <Typography variant='subtitle2'>Note :</Typography>
                        <Typography variant='subtitle1'>Explanation of train text & MAPE</Typography>
                        
                    </Stack>
                    
                   
                   </Container>
                </Card>
            </Box>
        </>
    );
};

export default PredictionTrainTest;
