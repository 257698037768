import { SET_MODEL_TRAINED, SET_ONGOING_MODEL_TRAINING } from '../Action';

const state = { model_trained: 0, ongoing_model_training: 0 };

const ModelStatusReducer = (modelTrainState = { ...state }, action) => {
    switch (action.type) {
        case SET_MODEL_TRAINED:
            return { ...modelTrainState, model_trained: action.payload };

        case SET_ONGOING_MODEL_TRAINING:
            return { ...modelTrainState, ongoing_model_training: action.payload };

        default:
            return modelTrainState;
    }
};

export default ModelStatusReducer;
