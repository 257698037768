import axios from 'axios';
import { ToastService } from './Shared/Alert';
import { Config } from './Shared';
import Store from './Redux/Store';
import { LOGOUT_SUCCESS } from './Redux/Action';
import AuthService from './Services/AuthService';
axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
axios.defaults.headers.common['Access-Control-Allow-Methods'] = 'DELETE, POST, GET, OPTIONS';
axios.defaults.headers.common['Access-Control-Allow-Headers'] = 'Content-Type, Authorization, X-Requested-With';
axios.defaults.baseURL = Config.apiURL;

axios.interceptors.response.use(
    (resp) => {
        if ((resp?.status === 200 || resp?.status === 201) && resp?.data?.msg) {
            const msg = resp?.data?.msg || resp?.data?.message;
            if (!!msg) ToastService.success(resp.data.msg);
        }
        return resp;
    },
    (error) => {
        console.log("error",error);
        if (error && error.response) {
            const { data, status } = error.response;
            console.log("data", data)

            const msg = data?.msg || data.message;
            console.log("data", data, msg)

            if (status === 500) {
                ToastService.error(msg);
            } 
            else if (status === 401 && msg) {
                // if (typeof msg === 'string') {
                //     if (!!msg) ToastService.warning(msg);
                // } else {
                //     if (!!msg) Object.keys(msg).forEach((m) => ToastService.warning(msg[m]));
                // }
                const refresh_token = JSON.parse(localStorage.getItem('refresh_token'));
                const token = JSON.parse(localStorage.getItem('user'))?.token || '';
                console.log("refresh_token",refresh_token);
                console.log("token",token);
                // axios.defaults.headers.common["Authorization"] = `Bearer ${token}`

                const originalRequest = {
                    url: error.config.url,
                    method: error.config.method,
                    data: error.config.data,
                    headers: {
                        'Content-Type': 'application/json'
                    }
                };

                console.log("originalRequest",originalRequest);

                Store.dispatch(AuthService.refreshToken(refresh_token),{
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                    .then((result) => {
                        console.log("Token Refresh Success:", result.data.token);
                        const newToken = result.data.token;
                        const { config: oldRequest } = error;
                        oldRequest.headers['Authorization'] = `Bearer ${newToken}`;
                        // axios.request({...oldRequest, headers: { 'Content-Type': 'application/json' },});
                        axios.request(oldRequest)
                            .then((response) => {
                                console.log("Original request successful:", response);
                            })
                            .catch((error) => {
                                console.error("Error in original request after token refresh:", error);
                        });
                    })
                    .catch((error) => {
                        console.error("Token Refresh Error:", error);
                        Store.dispatch({ type: LOGOUT_SUCCESS });
                    });
                ;

                // Store.dispatch({ type: LOGOUT_SUCCESS });
                
            } 
            // else if (status === 401 && msg) {
            //     const refresh_token = JSON.parse(localStorage.getItem('refresh_token'));
            //     const token = JSON.parse(localStorage.getItem('user'))?.token || '';
            //     console.log("refresh_token",refresh_token);
            //     console.log("token",token);
            
            //     const originalRequest = {
            //         url: error.config.url,
            //         method: error.config.method,
            //         data: error.config.data,
            //     };
            
            //     // Define an Axios interceptor to refresh the token and retry the original request
            //     const interceptor = axios.interceptors.response.use(
            //         response => {
            //             // If the response is successful, just return it
            //             return response;
            //         },
            //         async error => {
            //             // If the error status is 401 and there's a refresh token available
            //             if (error.response.status === 401 && refresh_token) {
            //                 // Remove the interceptor to prevent infinite loop
            //                 axios.interceptors.response.eject(interceptor);
                            
            //                 try {
            //                     // Refresh the token
            //                     const result = await Store.dispatch(AuthService.refreshToken(refresh_token), {
            //                         headers: {
            //                             'Content-Type': 'application/json'
            //                         }
            //                     });
            //                     // Update the token in local storage
            //                     const newToken = result.data.token;
            //                     localStorage.setItem('user', JSON.stringify({ token: newToken }));
            
            //                     // Retry the original request with the new token
            //                     return axios({
            //                         ...originalRequest,
            //                         headers: {
            //                             ...originalRequest.headers,
            //                             Authorization: `Bearer ${newToken}`
            //                         }
            //                     });
            //                 } catch (refreshError) {
            //                     // If token refresh fails, logout the user
            //                     console.error("Token Refresh Error:", refreshError);
            //                     Store.dispatch({ type: LOGOUT_SUCCESS });
            //                     throw refreshError;
            //                 }
            //             }
            //             // If the error is not due to token expiration or refresh token is not available, just throw the error
            //             throw error;
            //         }
            //     );
            
            //     // Continue with the original request
            //     return axios(originalRequest);
            // }
            else if (typeof msg === 'string') {
                if (!!msg) ToastService.warning(msg);
            } else {
                if (!!msg) Object.keys(msg).forEach((m) => ToastService.warning(msg[m]));
            }
            return Promise.reject(data);
        }
    }
);

// const refreshToken = async () => {
//     try {
//       const resp = await axios.post(`/auth/refresh`);
//       console.log("refresh token", resp);
//       return resp.data;
//     } catch (e) {
//       console.log("Error",e);
//     //   Store.dispatch({ type: LOGOUT_SUCCESS });   
//     }
// };

const Http = axios;

export default Http;
