import { LOGIN_SUCCESS, LOGOUT_SUCCESS, REFRESH_SUCCESS } from '../Action';
const checkedUser = localStorage.getItem('user');
const user = checkedUser ? JSON.parse(checkedUser) : null;
const state = { isAuthenticated: !!user, user };

const AuthReducers = (authState = { ...state }, action) => {
    console.log("aa", action.payload);
    switch (action.type) {
        case LOGIN_SUCCESS:
            localStorage.setItem('user', JSON.stringify(action.payload));
            return { ...authState, isAuthenticated: true, user: action.payload };

        case LOGOUT_SUCCESS:
            localStorage.removeItem('user');
            return { ...authState, isAuthenticated: false, user: null };

        case REFRESH_SUCCESS:
                localStorage.setItem('user', JSON.stringify(action.payload));
                return { ...authState, isAuthenticated: true, user: action.payload };

        default:
            return authState;
    }
};

export default AuthReducers;
