const Styles = (theme) => {
    return ({
        'html,body': {
            padding: 0,
            margin: 0,
            boxSizing: 'border-box'
        },
        '*': {
            boxSizing: 'border-box'
        },

        'html': {
            fontSize: '16px',

            '@media (max-width: 1599px)': {
                fontSize: '14px',
            },

            '@media (max-width: 1399px)': {
                fontSize: '12px',
            },

            '@media (max-width: 1299px)': {
                fontSize: '11px',
            },

            '@media (max-width: 1249px)': {
                fontSize: '10px',
            }
        },

        'body': {
            fontFamily: `'Nunito', sans-serif`,
            backgroundColor: theme.colors.bodyBgColor,
            minHeight: '100vh',
            color: theme.colors.color.primary,

            'div:where(.swal2-container)': {
                zIndex: theme.zIndex.tooltip
            },

            '.coming-soon': {
                position: 'relative',

                '&::after': {
                    content: `'Coming Soon...'`,
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    backgroundColor: `${theme.colors.componentBgColor}e8`,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                }
            },

            '.offline': {
                position: 'relative',

                '&::after': {
                    content: `'Offline...'`,
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    backgroundColor: `${theme.colors.componentBgColor}e8`,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                }
            },
            '.apexcharts-legend-series': {
                marginLeft: '40px'
            }

        },

        '.area-graph .apexcharts-canvas svg': {
            backgroundColor: `${theme.colors.componentBgColor} !important`
        },

        '@media (hover:hover)': {
            '*::-webkit-scrollbar': {
                width: '5px',
                height: '5px',
            },

            '*::-webkit-scrollbar-thumb': {
                backgroundColor: 'darkgrey',
                outline: '1px solid slategrey',
            }
        }
    })
}

export default Styles;